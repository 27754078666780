@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/scss/main";

body {
  background-color: #f5f5f5;
}

input:required + label::after,
select:required + label::after {
  color: rgb(255 0 0);
  content: " *";
}
