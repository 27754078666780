.login-container {
  background-image: url("../../assets/img/login-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  input,
  button {
    border-radius: 0;
  }
}

.something {
  background: #12323c;
  color: #fff;
  max-width: 380px;
}

.login {
  margin-top: 3rem;
}
