.dropdown-hack {
  background-color: #253c74;
  color: #fff;
  padding: 2rem;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 74px;
  z-index: 1000;

  a,
  .btn-link {
    color: #fff !important;
    padding: 0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.navbar {
  background-color: #fff !important;

  * {
    color: #253c74 !important;
    font-weight: bold;
    max-height: 3rem;
  }

  a:hover {
    text-decoration: underline;
  }
}
