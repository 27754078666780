.new-excursion-button {
  background-color: #1f314b;
  color: #f3f3f3;
  display: block;
  font-size: 100%;
  letter-spacing: 0.15rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.tab {
  background-color: #b0b0b0;
  border: solid #000 1px;
  padding: 1rem;
  text-align: center;
}

.active {
  background-color: #4dc3bb;
  font-weight: bolder;
}
