.standard-layout {
  > div:nth-child(1) {
    align-items: center;
    background-image: url("/assets/img/mussels_background.jpg");
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
}
